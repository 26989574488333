<template>
  <div class="chart-wrapper">
    <svg :width="width"
         :height="height">
      <g :transform="`translate(${padding.left}, ${padding.top})`" class="chart">
      </g>
      <line :x1="0"
            :x2="width"
            :y1="padding.top + chartHeight + hoverrectMehrYBottom"
            :y2="padding.top + chartHeight + hoverrectMehrYBottom"
            :stroke="divisionLineColor"
            stroke-width="0.5">
      </line>
      <g :transform="`translate(${sliderMargin}, ${padding.top + chartHeight + hoverrectMehrYBottom + 35})`"
         class="slider-wrapper">
        <slider v-if="sliderWidth && staticDomain.length && domain.length"
                :sliderWidth="sliderWidth"
                :staticDomain="staticDomain"
                :initialDomain="domain"
                :maximumSliderRange="maximumSliderRange"
                @dragged="onSliderDrag"
        />
      </g>
      <Tooltip :svgWidth="width"
               :svgHeight="height"
               :obj="tooltipObj"
               :handleOverflowX="true"
               :handleOverflowY="false">
      </Tooltip>
    </svg>
  </div>
</template>

<script>
import Tooltip from '../d3-assets/Tooltip'
import Slider from '../d3-assets/Slider'
import patternify from '../d3-assets/patternify'
import { thousandsFormat } from '../d3-assets/formatters'

export default {
  props: [
    'dataArray',
    'birthYear'
  ],
  components: {
    Tooltip,
    Slider
  },
  data () {
    return {
      width: 0,
      height: 550,
      padding: {
        left: 110,
        right: 15,
        top: 35,
        bottom: 15
      },
      sliderHeight: 180,
      hoverrectMehrYBottom: 60,
      mehrHeight: 60,
      axisCircleSize: 4,
      capacityPercentage: 0.2,
      divisionLineColor: '#404A65',
      colors: {
        surplus: '#FEC600',
        shortfall: '#D90060'
      },
      circleColor: '#0065FF',
      darkColor: '#A5ADBA',
      domain: [],
      staticDomain: [],
      tooltipObj: {
        visible: false
      },
      svg: null,
      chart: null
    }
  },
  computed: {
    chartHeight () {
      return this.height - this.padding.top - this.padding.bottom - this.sliderHeight
    },
    chartWidth () {
      return this.width - this.padding.left - this.padding.right
    },
    maximumSliderRange () {
      return Math.round(this.dataArray.length / 1.8)
    },
    sliderWidth () {
      return this.width * 0.7 // 70% of whole width;
    },
    sliderMargin () {
      return this.width * 0.15 // 15% each side
    },
    sliderScale () {
      const padding = 60
      return this.$d3
        .scaleBand()
        .domain(this.staticDomain)
        .range([0, this.sliderWidth - padding * 2])
    },
    xScale () {
      return this.$d3.scaleBand()
        .domain(this.domain)
        .range([0, this.chartWidth])
        .paddingInner([0.02])
        .paddingOuter([0.1])
    },
    yScale () {
      const max = this.$d3.max(this.dataArray, d => d.value)
      const min = this.$d3.min(this.dataArray, d => d.value)

      return this.$d3.scaleLinear()
        .domain([Math.min(0, min), max])
        .range([this.chartHeight, 0])
    },
    bars () {
      return this.dataArray.filter(x => this.domain.indexOf(x.year) > -1)
    }
  },
  methods: {
    rectmouseover (d, i) {
      const html = `<div class="toolTip">
                    <div class="mt-2 year">
                      <strong><span>${d.year}</span></strong> ( age of <strong><span>${d.year - this.birthYear}</span></strong> )
                    </div>
                    <div class="d-flex mt-4">
                      <div class="mr-3">
                        <span class="income-span" style="background-color: ${d.name.length ? this.colors[d.name] : 'inherit'}"></span><span class="ml-2 label-span">${d.name}</span>
                      </div>
                      <div class="ml-4">
                        <strong><span id="amount-span">${thousandsFormat(d.value)}</span> SEK</strong>
                      </div>
                    </div>
                  </div>`

      const x = this.xScale(d.year) + this.xScale.bandwidth() / 2 + this.padding.left
      const y = this.padding.top * 1.5

      this.tooltipObj = {
        x: x,
        y: y,
        html: html,
        visible: true
      }

      // current tick selection
      const xTick = this.chart.select(`#xTick-${d.year}`)

      // make circle bigger and filled white
      const xTickCircle = xTick.select('circle')
      xTickCircle.attr('fill', '#fff')
        .attr('stroke', '#1971ff')
        .attr('r', 5)

      // display hover rect
      const hoverrect = this.$d3.select('.hoverrect')
      hoverrect.attr('x', this.xScale(d.year))
        .style('display', 'block')

      // make texts a little bit bigger
      xTick.selectAll('text').attr('fill', '#fff').attr('font-weight', 600)
    },
    rectmouseout (d, i) {
      // current tick selection
      const xTick = this.chart.select(`#xTick-${d.year}`)

      // make circle smaller
      const xTickCircle = xTick.select('circle')
      xTickCircle.attr('fill', '#1971ff')
        .attr('stroke', '#fff')
        .attr('r', 4)

      // hide hover rect
      this.$d3.select('.hoverrect').style('display', 'none')

      // make texts smaller
      xTick.selectAll('text').attr('fill', this.darkColor).attr('font-weight', 400)
      this.tooltipObj.visible = false
    },
    calcOpacity (d, i) {
      const length = this.domain[this.domain.length - 1] - this.domain[0]
      const l = Math.round(length * this.capacityPercentage)
      const h = length - l

      const sc = this.$d3.scaleLinear().clamp(true)

      if (i <= l) {
        sc.domain([0, l]).range([0.25, 1])
        return sc(i)
      }
      if (i >= h) {
        sc.domain([h, this.domain.length - 1]).range([1, 0.25])
        return sc(i)
      }
      return 1
    },
    drawAxis () {
      const tickSize = this.chartHeight
      const xAxis = this.$d3.axisBottom(this.xScale).tickSize(-(tickSize))
      const yAxis = this.$d3.axisLeft(this.yScale).ticks(5).tickFormat(d => thousandsFormat(d) + ' Kr')

      // append xAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'xAxis'
      })
        .attr('class', 'xAxis axis')
        .attr('transform', `translate(${0}, ${this.chartHeight})`)
        .raise()
        .call(xAxis)

      // append yAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'yAxis'
      })
        .attr('class', 'yAxis axis')
        .raise()
        .call(yAxis)
    },
    drawBars () {
      // bars
      patternify({
        tag: 'rect',
        selector: 'bar',
        container: this.chart,
        data: this.bars
      })
        .attr('x', d => this.xScale(d.year))
        .attr('y', d => {
          if (d.name === 'surplus') {
            return this.yScale(d.value)
          } else {
            return this.yScale(0)
          }
        })
        .attr('width', d => this.xScale.bandwidth())
        .attr('height', d => {
          if (d.name === 'surplus') {
            return Math.abs(this.yScale(d.value) - this.yScale(0))
          } else if (d.name === 'shortfall') {
            return Math.abs(this.yScale(0) - this.yScale(d.value))
          }
          return 0
        })
        .attr('data-year', d => d.year)
        .attr('fill', d => d.name.length ? this.colors[d.name] : null)
        .attr('opacity', (d, i) => this.calcOpacity(d, i))

      // hidden rectangles
      patternify({
        tag: 'rect',
        selector: 'hiddenrect',
        container: this.chart,
        data: this.bars
      })
        .attr('x', d => this.xScale(d.year))
        .attr('y', 0)
        .attr('width', d => this.xScale.bandwidth())
        .attr('height', d => this.chartHeight + this.hoverrectMehrYBottom)
        .attr('data-year', d => d.year)
        .attr('opacity', (d, i) => this.calcOpacity(d, i))
        .on('mouseover', (d, i) => this.rectmouseover(d, i))
        .on('mouseout', (d, i) => this.rectmouseout(d, i))

      // hover rectangle
      patternify({
        tag: 'rect',
        selector: 'hoverrect',
        container: this.chart
      })
        .attr('class', 'hoverrect')
        .attr('opacity', 0.35)
        .attr('fill', 'black')
        .attr('x', 0)
        .attr('y', -this.padding.top)
        .attr('width', d => this.xScale.bandwidth())
        .attr('height', d => this.chartHeight + this.padding.top + this.hoverrectMehrYBottom)
    },
    adjustAxis () {
      const self = this
      const ticks = this.chart.select('g.xAxis')
        .selectAll('.tick')
        .attr('data-age', (d, i) => {
          const year = this.xScale.domain()[0] + i
          return year - this.birthYear
        })
        .attr('id', (d, i) => {
          const year = this.xScale.domain()[0] + i
          return `xTick-${year}`
        })
        .attr('opacity', (d, i) => {
          return this.calcOpacity(d, i)
        })

      ticks.select('line')
        .attr('stroke', this.darkColor)
        .attr('stroke-width', 0.5)
        .attr('stroke-dasharray', '3 1.5')

      // reposition texts within the axis
      ticks.select('text')
        .attr('class', 'tick-year')
        .attr('dy', '4em')
        .attr('fill', this.darkColor)
        .attr('font-family', 'Roboto')

      // append circles
      patternify({
        container: ticks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('fill', this.circleColor)
        .attr('stroke', '#fff')
        .attr('stroke-width', 1)

      // append ages
      patternify({
        container: ticks,
        tag: 'text',
        selector: 'secondaryAxisText'
      })
        .attr('class', 'secondaryAxisText')
        .attr('dy', '2.5em')
        .attr('font-family', 'Roboto')
        .text(function (d, i) {
          const tick = self.$d3.select(this.parentElement)
          return tick.attr('data-age')
        })

      // adjust xAxis
      const yTicks = this.chart.select('g.yAxis')
        .selectAll('.tick')

      // reposition lines
      yTicks.select('line')
        .attr('x1', (d, i) => {
          return -7
        })
        .attr('x2', (d, i) => {
          if (d === 0) {
            return this.chartWidth
          }
          return 4
        })
        .attr('stroke', d => {
          if (d === 0) {
            return '#ffffff'
          }
          return '#A5ADBA'
        })
        .attr('stroke-width', 1.5)

      yTicks.select('text')
        .attr('x', -24)
        .attr('font-family', 'Roboto')

      // append circles
      patternify({
        container: yTicks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('cx', -10)
        .attr('fill', '#6D7290')
        .attr('stroke', '#ffffff')
        .attr('stroke-width', 1)
    },
    setWidth () {
      this.width = this.$el.offsetWidth
    },
    onResize () {
      this.setWidth()
      this.setDomain()
      this.draw()
    },
    setDomain () {
      const min = this.$d3.min(this.dataArray, d => d.year)
      const max = this.$d3.max(this.dataArray, d => d.year)
      this.staticDomain = this.$d3.range(min, max + 1)
      if (!this.domain.length) {
        this.domain = this.$d3.range(min, min + this.maximumSliderRange + 1)
      }
    },
    draw () {
      this.drawBars()
      this.drawAxis()
      this.adjustAxis()
    },
    onSliderDrag (domain) {
      this.domain = domain
      this.draw()
    }
  },
  mounted () {
    this.svg = this.$d3.select(this.$el).select('svg')
    this.chart = this.svg.select('g.chart')
    this.$d3.select(window).on('resize', this.onResize)
    this.onResize()
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
