<template>
  <div class="overview">
    <b-container class="main-gradient light" fluid>
      <b-row>
        <b-col no-gutters>
          <b-row>
            <b-col class="chart-container">
              <cashflow-chart
                :dataArray="dataArray"
                :birthYear="1980"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import cashflowChart from '@/components/d3charts/cashflow/index'

export default {
  components: {
    cashflowChart
  },
  data () {
    return {
      dataArray: [
        { value: 1000, year: 2011, name: 'surplus' }, // the name must be either surplus or shortfall
        { value: -200, year: 2012, name: 'shortfall' },
        { value: 100, year: 2013, name: 'surplus' },
        { value: 200, year: 2014, name: 'surplus' },
        { value: -300, year: 2015, name: 'shortfall' },
        { value: 100, year: 2016, name: 'surplus' },
        { value: -200, year: 2017, name: 'shortfall' },
        { value: 400, year: 2018, name: 'surplus' },
        { value: -200, year: 2019, name: 'shortfall' },
        { value: 200, year: 2020, name: 'surplus' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-container {
    padding-top: 50px;
  }
</style>
